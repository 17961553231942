<template>
  <v-select
    :items="items"
    v-model="selectedItems"
    :item-value="itemValue"
    :item-text="itemText"
    :label="label"
    :multiple="multiple"
  >
    <template v-slot:prepend-item v-if="selectAllItem">
      <v-list-item ripple @click="toggle" ref="listItem">
        <v-list-item-action>
          <v-icon :color="selectedItems.length > 0 ? 'indigo darken-4' : ''">{{ icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Выбрать все</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
    <template v-slot:selection="{ item, index }">
      <div v-if="index < 1" class="v-select__selection v-select__selection--comma">
        {{ item[itemText] }},
      </div>
      <div v-if="(index === 1)" class="grey--text">(+{{ selectedItems.length - 1 }})</div>
    </template>
  </v-select>
</template>

<script>
export default {
  data: () => ({
    selectedItems: [],
  }),
  props: {
    items: Array,
    value: Array,
    defaultValue: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    itemValue: {
      type: String,
      default: '',
    },
    itemText: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    selectAllItem: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.selectedItems = this.value;
  },
  model: {
    event: 'change',
    prop: 'value',
  },
  computed: {
    allSelected() {
      return this.selectedItems.length === this.items.length;
    },
    someSelected() {
      return this.selectedItems.length > 0 && !this.allSelected;
    },
    icon() {
      if (this.allSelected) return 'mdi-close-box';
      if (this.someSelected) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
  },
  watch: {
    selectedItems() {
      this.$emit('change', this.selectedItems);
    },
  },
  created() {
    this.selectedItems = this.defaultValue;
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.allSelected) {
          this.selectedItems = [];
        } else {
          this.selectedItems = this.items.map((item) => item.id);
        }
      });
    },
  },
};
</script>
