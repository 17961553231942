<template>
  <v-row justify="center">
    <v-dialog max-width="640px" v-model="dialogState">
      <v-card>
        <v-card-title>
          <span class="headline">Параметры</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <!-- <v-col cols="12">
                <v-text-field label="Название пресета" required></v-text-field>
              </v-col> -->
              <v-col cols="12">
                <v-autocomplete
                  label="Регион"
                  v-model="region"
                  :items="params.regions"
                  item-value="id"
                  item-text="name"
                >
                  <template v-slot:item="data">
                    {{ Array(data.item.level).fill('—').join('') }} {{ data.item.name }}
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  label="Пол"
                  v-model="sex"
                  :items="params.sex"
                  item-value="id"
                  item-text="name"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <custom-select
                  label="Возрастная группа"
                  v-model="ageGroups"
                  :items="params.ageGroup"
                  item-value="id"
                  selectAllItem
                  item-text="comment"
                  multiple
                ></custom-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  label="Тип населения"
                  v-model="populationGroup"
                  :items="params.populationGroup"
                  item-value="id"
                  item-text="comment"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  label="Год"
                  v-model="year"
                  :items="params.years"
                  item-value="value"
                  item-text="value"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red darken-1" text @click="clearParams">Очистить параметры</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogState = false">Отмена</v-btn>
          <v-btn color="blue darken-1" text @click="dialogSubmited">Применить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirm-dialog ref="confirm" />
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ConfirmDialog from './ConfirmDialog.vue'
import CustomSelect from './CustomSelect.vue'

export default {
  data: () => ({
    dialog: false,
    region: 94,
    sex: 3,
    ageGroups: [],
    populationGroup: 3,
    year: 2019,
  }),
  components: {
    ConfirmDialog,
    CustomSelect,
  },
  props: {
    params: {
      type: Object,
      default: () => ({
        reasons: [],
        ageGroup: [],
        regions: [],
        populationGroup: [],
        sex: [],
        years: [],
      }),
    },
  },
  computed: {
    ...mapGetters(['getDialogState']),
    dialogState: {
      get() {
        return this.getDialogState
      },
      set(value) {
        this.setDialogState(value)
      },
    },
  },
  methods: {
    ...mapMutations({
      clearBarsParams: 'ly/CLEAR_BARS_PARAMS',
      addBarsParams: 'ly/ADD_BARS_PARAMS',
      setDialogState: 'setDialogState',
    }),
    ...mapActions({
      addBarsParams2: 'ly2/setReasonsCompareListParamsAndLoadData',
      clearBarsParams2: 'ly2/setReasonsCompareListParamsAndLoadData',
    }),
    dialogSubmited() {
      const param = {
        region: this.region,
        sex: this.sex,
        ageGroups: this.ageGroups,
        populationGroup: this.populationGroup,
        year: this.year,
      }
      this.addBarsParams(param)
      this.addBarsParams2(param)
      this.dialogState = false
    },
    clearParams() {
      this.$refs.confirm.open('Очистить параметры?', '').then(resolve => {
        if (resolve) {
          this.clearBarsParams()
          this.addBarsParams2()
        }
      })
    },
  },
}
</script>
