<template>
  <div class="group-bar-chart">
    <div style="min-height: 22px">
      <ChartLegend :items="legend" @input="datasetChanged" :active="activeDataset" />
    </div>
    <SvgSizeTemplate :width="width" :height="height" :margin="margin" />
  </div>
</template>

<script>
import {
  scaleBand,
  scaleLinear,
  axisTop,
  max,
  scaleOrdinal,
  schemeSet2,
  event as d3event,
} from 'd3'

import * as darkin from 'd3-axis-darkin'
import wrap from '../darkin/wrap'
import ChartLegend from './ChartLegend'

import SvgSizeTemplate from './SvgSizeTemplate'
import d3PlotMixin from './d3PlotMixin'

export default {
  components: { ChartLegend, SvgSizeTemplate },
  mixins: [d3PlotMixin],
  props: {
    width: {
      type: Number,
      default: 300,
    },
    groupHeight: {
      type: Number,
      default: 200,
    },
    data: {
      type: Array,
      default: () => [],
    },
    params: {
      type: Array,
      default: () => [],
    },
    events: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Object,
      default: () => ({
        animationDuration: 500,
      }),
    },
  },
  data() {
    return {
      margin: {
        left: 250,
        top: 25,
        right: 20,
        bottom: 20,
      },
      activeDataset: null,
    }
  },
  mounted() {
    this.setViewBoxWithTransition()
    this.render()
  },
  watch: {
    viewBox() {
      this.setViewBoxWithTransition()
    },
    data() {
      this.render()
    },
    activeDataset() {
      this.render()
    },
  },
  computed: {
    height() {
      return this.groupHeight * this.data.length + this.margin.top + this.margin.bottom
    },
    datasetsCount() {
      return this.data.length && this.data[0].values ? this.data[0].values.length : 0
    },
    groupsCount() {
      return this.data.length
    },
    spaceBetweenGroups() {
      return 8
    },
    colors() {
      return scaleOrdinal(schemeSet2)
    },
    names() {
      return scaleOrdinal(this.params.map(d => d.name))
    },
    legend() {
      return this.data.length
        ? this.data[0].values.map((d, i) => ({
            id: i,
            name: this.names(i),
            color: this.colors(i),
          }))
        : []
    },
    xScale() {
      return scaleLinear()
        .domain([0, max(this.data.map(d => max(d.values)))])
        .range([0, this.mainPlotAreaWidth])
    },
    yScale() {
      return scaleBand()
        .domain(this.data.map(d => d.name))
        .range([0, this.mainPlotAreaHeight])
    },
    yScaleInGroup() {
      return scaleBand()
        .domain([...Array(this.data[0]?.values.length)].map((d, i) => i))
        .range([this.spaceBetweenGroups / 2, this.groupHeight - this.spaceBetweenGroups / 2])
        .paddingInner(0.1)
    },
  },
  methods: {
    setViewBoxWithTransition() {
      this.svg.transition().duration(this.animationDuration).attr('viewBox', this.viewBox)
    },
    getOpacity(i) {
      return this.activeDataset === null || this.activeDataset === i ? 1 : 0.7
    },
    enterRects(enter) {
      const { colors, yScaleInGroup, animationDuration, xScale, getOpacity } = this
      enter
        .append('g')
        .classed('item', true)
        .call(enter => {
          enter
            .append('rect')
            .attr('x', 0)
            .attr('y', (d, i) => yScaleInGroup(i))
            .attr('height', yScaleInGroup.bandwidth())
            .attr('fill', (d, i) => colors(i))
            .attr('opacity', (d, i) => getOpacity(i))
            .call(enter => {
              enter
                .transition()
                .duration(animationDuration)
                .attr('width', d => xScale(d))
            })
        })
    },
    updateRects(update) {
      const { animationDuration, yScaleInGroup, colors, xScale, getOpacity } = this
      update.select('rect').call(update =>
        update
          .transition('update')
          .duration(animationDuration)
          .attr('y', (d, i) => yScaleInGroup(i))
          .attr('opacity', (d, i) => getOpacity(i))
          .attr('height', yScaleInGroup.bandwidth())
          .attr('fill', (d, i) => colors(i))
          .attr('width', d => xScale(d))
      )
    },
    exitRects(exit) {
      const { animationDuration } = this
      exit
        .select('rect')
        .transition()
        .duration(animationDuration)
        .attr('width', 0)
        .on('end', () => exit.remove())
    },
    createXAxis() {
      const { svg, animationDuration, xScale, mainPlotAreaHeight } = this
      svg
        .select('g.x-axis-top')
        .style('color', '#555')
        .transition()
        .duration(animationDuration)
        .call(axis => {
          axis.call(
            axisTop()
              .scale(xScale)
              .tickSize(5 - mainPlotAreaHeight)
          )
          axis.selectAll('line').attr('stroke', 'LightGrey')
        })
      svg.select('g.x-axis-top').select('.domain').remove()
    },
    createYAxis() {
      const { svg, animationDuration, yScale } = this
      svg
        .select('g.y-axis-left')
        .style('color', '#555')
        .transition()
        .duration(animationDuration)
        .call(axis => {
          axis.call(darkin.axisLeft(yScale)).selectAll('.tick text').call(wrap, 250)
          axis.select('.domain').attr('stroke', 'LightGrey')
          axis.selectAll('line').attr('stroke', 'LightGrey')
        })
    },
    bindEventsToSvg() {
      const { svg } = this
      svg
        .select('g.content')
        .selectAll('g.group')
        .selectAll('rect')
        .call(elem => this.bindEvents(elem, this.events))
        .on('mouseover.internal', (d, i) => {
          this.activeDataset = i
        })
        .on('mouseout.internal', () => {
          this.activeDataset = null
        })
    },
    enterGroups(enter) {
      const { enterRects, updateRects, exitRects, groupHeight } = this
      enter
        .append('g')
        .classed('group', true)
        .attr('transform', (d, i) => `translate(0, ${i * groupHeight})`)
        .selectAll('g.item')
        .data(d => d.values)
        .join(enterRects, updateRects, exitRects)
    },
    updateGroups(update) {
      const { enterRects, updateRects, exitRects } = this
      update
        .selectAll('g.item')
        .data(d => d.values)
        .join(enterRects, updateRects, exitRects)
    },
    exitGroups(exit) {
      const { animationDuration } = this
      exit
        .selectAll('rect')
        .transition()
        .duration(animationDuration)
        .attr('width', 0)
        .on('end', () => exit.remove())
    },
    createGroups() {
      const { data, svg, enterGroups, updateGroups, exitGroups } = this
      svg
        .select('g.content')
        .selectAll('g.group')
        .data(data)
        .join(enterGroups, updateGroups, exitGroups)
    },
    render() {
      this.createGroups()
      this.bindEventsToSvg()
      this.createXAxis()
      this.createYAxis()
    },
    datasetChanged(value) {
      this.activeDataset = value
    },
    bindEvents(element, events) {
      if (typeof events !== 'object') return
      Object.keys(events).forEach(e => {
        const f = events[e]
        element.on(e, function (a, b, c) {
          f(d3event, a, b, c)
        })
      })
    },
  },
}
</script>

<style scoped>
.x-axis-top >>> .tick line {
  stroke: #c0c0bb;
}
</style>
