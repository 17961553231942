<template>
  <div>
    <div style="max-width: 1090px">
      <GroupBarChat
        :groupHeight="100"
        :width="1090"
        :data="dataForGroupBarChat"
        :params="legendForGroupBarChat"
        :events="{ mouseover: onMouseOver, mouseout: onMouseOut }"
      />
    </div>
    <v-tooltip
      top
      :value="tooltipIsOpen"
      :position-x="tooltipPositionX"
      :position-y="tooltipPositionY"
      >{{ tooltipValue }}</v-tooltip
    >
    <params-dialog :params="dictionaries"></params-dialog>
  </div>
</template>

<script>
const BASE10 = 10

import { mapGetters, mapActions } from 'vuex'
import ParamsDialog from '../old/ParamsDialog.vue'
import GroupBarChat from '../../d3new/GroupBarChart'
import { format } from 'd3'

export default {
  data() {
    return {
      dataForGroupBarChat: [],
      legendForGroupBarChat: [],
      prev: [],
      tooltipActivator: null,
      tooltipValue: null,
      tooltipIsOpen: false,
      tooltipPositionX: null,
      tooltipPositionY: null,
    }
  },
  components: {
    ParamsDialog,
    GroupBarChat,
  },
  mounted() {
    const legend = this.getLegendForGroupBarChat()
    this.dataForGroupBarChat = this.getPreparedReasonsForGroupBarChat()
    this.legendForGroupBarChat = legend
    this.setReasonsParams(legend.map((d, i) => ({ ...d, id: i })))
  },
  watch: {
    getReasons() {
      const legend = this.getLegendForGroupBarChat()

      this.dataForGroupBarChat = this.getPreparedReasonsForGroupBarChat()
      this.legendForGroupBarChat = legend
      this.setReasonsParams(legend.map((d, i) => ({ ...d, id: i })))
    },
  },
  methods: {
    ...mapActions({
      setReasonsParams: 'ly2/setReasonsParams',
      setTooltip: 'tt/setTooltip',
    }),

    // Данные приходят с группировокй по срезу (параметру). Для графика нужна группировка по причине
    getPreparedReasonsForGroupBarChat() {
      const { getReasons } = this
      if (!getReasons || !getReasons.length) return
      const result = []
      for (let i = 0; i < getReasons.length; i += 1) {
        for (let j = 0; j < getReasons[i].length; j += 1) {
          if (i === 0) {
            result.push({
              name: this.getReasonNameById(getReasons[i][j].reasonId),
              values: [],
            })
          }
          result[j].values.push(parseFloat(getReasons[i][j].value))
        }
      }
      return result.sort((a, b) => b.values[0] - a.values[0])
    },

    // todo вынести всю работу со словарями в отдельный файл
    getReasonNameById(reasonId) {
      return this.dictionaries.reasons.find(r => r.id === reasonId)?.name
    },
    getRegionNameById(regionId) {
      return this.dictionaries.regions.find(r => r.id === regionId)?.name
    },
    getAgeDiapasonById(ageId) {
      return this.getReasonsAdditionals.ageGroups.find(a => a.id === ageId).name
    },
    getCollapseAgesDiapasonsString(ages) {
      if (ages.length === 0 || ages.length === this.dictionaries.ageGroup.length) {
        return 'все возраста'
      }

      const agesDiapasons = ages.map(item => this.getAgeDiapasonById(item))

      const replaceFunction = match => {
        const prevEnd = parseInt(match.split(';')[0], BASE10)
        const nextStart = parseInt(match.split(';')[1], BASE10)
        return nextStart - prevEnd === 1 ? '' : match
      }

      return agesDiapasons
        .sort((a, b) => parseInt(a, BASE10) - parseInt(b, BASE10))
        .join(';')
        .replace(/^0;/, '0-0;')
        .replace(/\+$/, '-+')
        .replaceAll(/\d+;\d+-/g, replaceFunction)
        .replace(/^0-0;/, '0;')
        .replace(/-\+$/, '+')
        .replaceAll(';', ', ')
    },

    getLegendForGroupBarChat() {
      const readableSex = {
        1: 'мужчины',
        2: 'женщины',
        3: 'оба пола',
      }
      const readablePopulation = {
        1: 'сельское',
        2: 'городское',
        3: 'городское и сельское',
      }
      const getLegendString = (item, i) => {
        if (this.getReasonsAdditionals) {
          const reg = this.getReasonsAdditionals.params[i].region
          const sex = readableSex[item.sex]
          const age = this.getCollapseAgesDiapasonsString(item.ageGroups)
          const pop = readablePopulation[item.populationGroup]
          return [reg, sex, pop, age, item.year].join(', ')
        } else {
          return ''
        }
      }
      return this.barsParams.map((item, i) => ({ name: getLegendString(item, i) }))
    },

    onMouseOver(event, b, c) {
      const tooltip = {}
      const rect = event.target.getBoundingClientRect()
      tooltip.title = this.legendForGroupBarChat[c].name
      tooltip.text = `Потерянные годы: ${format('.3~')(b)}`
      tooltip.top = rect.y + window.pageYOffset
      tooltip.left = rect.x + rect.width / 2 + window.pageXOffset
      tooltip.show = true
      this.setTooltip(tooltip)
    },
    onMouseOut() {
      this.setTooltip({ show: false })
    },
  },
  computed: {
    ...mapGetters({
      dictionaries: 'ly2/getDictionaries',
      barsParams: 'ly2/getBarsParams',
      getReasons: 'ly2/getReasonsData',
      getReasonsAdditionals: 'ly2/getReasonsAdditionals',
    }),
  },
}
</script>
