import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSelect,{attrs:{"items":_vm.items,"item-value":_vm.itemValue,"item-text":_vm.itemText,"label":_vm.label,"multiple":_vm.multiple},scopedSlots:_vm._u([(_vm.selectAllItem)?{key:"prepend-item",fn:function(){return [_c(VListItem,{ref:"listItem",attrs:{"ripple":""},on:{"click":_vm.toggle}},[_c(VListItemAction,[_c(VIcon,{attrs:{"color":_vm.selectedItems.length > 0 ? 'indigo darken-4' : ''}},[_vm._v(_vm._s(_vm.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Выбрать все")])],1)],1),_c(VDivider,{staticClass:"mt-2"})]},proxy:true}:null,{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index < 1)?_c('div',{staticClass:"v-select__selection v-select__selection--comma"},[_vm._v(" "+_vm._s(item[_vm.itemText])+", ")]):_vm._e(),((index === 1))?_c('div',{staticClass:"grey--text"},[_vm._v("(+"+_vm._s(_vm.selectedItems.length - 1)+")")]):_vm._e()]}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})}
var staticRenderFns = []

export { render, staticRenderFns }