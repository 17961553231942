<template>
  <v-container class="mt-n8">
    <div v-if="getReasonsParams">
      <v-chip
        v-for="item of typografedReasonsParams"
        :key="item.id"
        class="ma-1"
        close
        @click:close="removeItem(item.id)"
      >
        {{ item.name }}
      </v-chip>
    </div>

    <v-btn
      @click="setDialogState(true)"
      rounded
      x-large
      color="deep-orange lighten-1"
      dark
      class="d-flex mx-auto my-3"
    >
      <v-icon>mdi-plus</v-icon>
      <span>Добавить параметр</span>
    </v-btn>
  </v-container>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      getReasonsParams: 'ly2/getReasonsParams',
    }),
    // вынести в отдельную функцию такая же есть в selectReasons
    typografedReasonsParams() {
      return this.getReasonsParams.map(reasonParam => ({
        ...reasonParam,
        name: reasonParam.name.replaceAll(/(\s\S{1,2})\s/g, '$1\xa0'),
      }))
    },
  },
  methods: {
    ...mapMutations({
      setDialogState: 'setDialogState',
    }),
    ...mapActions({ removeBarsParam: 'ly2/setReasonsCompareListParamsAndLoadData' }),
    removeItem(id) {
      this.removeBarsParam(id)
    },
  },
}
</script>

<style>
.v-chip.v-size--default {
  height: auto !important;
  white-space: normal;
  min-height: 32px;
}
</style>
