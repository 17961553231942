<template>
  <div>
    <TopMenu />

    <ParamsPanel :widthOpen="470" alwaysScroll>
      <ReasonsParams />
      <SelectReasons />
    </ParamsPanel>

    <MainContent>
      <PageHeader title="Сравнение потерь" />
      <ReasonsContent />
    </MainContent>
  </div>
</template>

<script>
import TopMenu from '../components/layout/TopMenu'
import ParamsPanel from '../components/layout/ParamsPanel'
import MainContent from '../components/layout/MainContent'
import ReasonsContent from '../components/content/ReasonsContent'
import PageHeader from '../components/layout/PageHeader'

import SelectReasons from '../components/selects/SelectReasons'
import ReasonsParams from '../components/selects/ReasonsParams'

export default {
  name: 'ReaSons',
  components: {
    TopMenu,
    ParamsPanel,
    MainContent,
    ReasonsContent,
    SelectReasons,
    PageHeader,
    ReasonsParams,
  },
}
</script>
